/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

function Button({ children, size, type, outline, narrow, ...props }) {
  let className =
    'flex items-center justify-center py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-white ';

  className = narrow ? className.concat('px-2 ') : className.concat('px-4 ');

  switch (type) {
    case 'danger':
      className = outline
        ? className.concat(
            'border-pink-500 hover:bg-pink-500 text-pink-500 hover:text-white'
          )
        : className.concat('bg-pink-600 hover:bg-pink-700');
      break;
    default:
      className = outline
        ? className.concat(
            'border-purple-600 hover:bg-purple-700 text-purple-600 hover:text-white'
          )
        : className.concat('bg-purple-600 hover:bg-purple-700');
  }

  switch (size) {
    case 'small': {
      return (
        <button
          type="button"
          className="mr-3 inline-block px-4 py-1.5 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white"
          {...props}
        >
          {children}
        </button>
      );
    }
    case 'large': {
      <button
        type="button"
        className="mr-3 inline-block px-7 py-4 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-sm ease-soft-in tracking-normal shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white"
        {...props}
      >
        {children}
      </button>;
    }
    // eslint-disable-next-line no-fallthrough
    case 'medium':
    default: {
      return (
        <button type="button" className={className} {...props}>
          {children}
        </button>
      );
    }
  }
}

Button.defaultProps = {
  size: 'medium',
  type: 'primary',
  outline: false,
  narrow: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  outline: PropTypes.bool,
  narrow: PropTypes.bool,
};

export default Button;
