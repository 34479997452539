/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { CgSpinner } from 'react-icons/cg';
import LeftPanelRow from './LeftPanelRow';
import { isEmpty } from '../../../../../utils';

function LeftPanelList({ items, header, onSelectedItem }) {
  return (
    <>
      {!isEmpty(header) && (
        <div className="p-4 sm:px-6 border-b w-full bg-gray-50 rounded-t-lg border-b border-gray-200">
          <h3 className="text-base leading-6 font-medium text-gray-900 dark:text-white flex items-center">
            <span className="mr-2">
              <CgSpinner className="animate-spin" />
            </span>
            <span>Incoming Requests</span>
          </h3>
        </div>
      )}
      <div className="overscroll-y-auto scrolling-touch overflow-scroll">
        <ul className="flex flex-col divide divide-y ">
          {items.map((i) => (
            <LeftPanelRow
              rowItem={i}
              id={i.id}
              key={i.id}
              onSelectedItem={onSelectedItem}
            />
          ))}
        </ul>
      </div>
    </>
  );
}

LeftPanelList.defaultProps = {
  items: [],
  header: '',
};

LeftPanelList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      ts: PropTypes.number,
      request: PropTypes.shape({
        body: PropTypes.object,
        method: PropTypes.string,
        headers: PropTypes.object,
      }),
      meta: PropTypes.object,
    })
  ),
  header: PropTypes.string,
};

export default LeftPanelList;
