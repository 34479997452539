import PropTypes from 'prop-types';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from './Button';

import { isEmpty } from '../../utils';

function CopyButton({ icon, text, outline, type, copyText, successText }) {
  const [copied, setCopied] = useState(false);
  const className = isEmpty(text) ? 'flex' : 'mr-2';
  const narrow = isEmpty(text);
  return (
    <CopyToClipboard text={copyText} onCopy={(_, result) => setCopied(result)}>
      <Button outline={outline} narrow={narrow} type={type}>
        <span className={className}>{icon}</span>
        {copied ? successText : text}
      </Button>
    </CopyToClipboard>
  );
}

CopyButton.defaultProps = {
  text: '',
  outline: false,
  type: 'primary',
  successText: '',
};

CopyButton.propTypes = {
  icon: PropTypes.node.isRequired,
  successText: PropTypes.string,
  text: PropTypes.string,
  copyText: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
};

export default CopyButton;
