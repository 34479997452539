import PropTypes from 'prop-types';
import Button from './Button';

import { isEmpty } from '../../utils';

function IconButton({ icon, text, outline, type }) {
  const className = isEmpty(text) ? 'flex' : 'mr-2';
  const narrow = isEmpty(text);
  return (
    <Button outline={outline} narrow={narrow} type={type}>
      <span className={className}>{icon}</span>
      {text}
    </Button>
  );
}

IconButton.defaultProps = {
  text: '',
  outline: false,
  type: 'primary',
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  outline: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger']),
};

export default IconButton;
