/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Tab from '../../../../../components/Tab/Tab';

import TabHeader from './TabContents/TabHeaders';
import TabPreview from './TabContents/TabPreview';

function RightPanel({ item }) {
  const tabs = [
    {
      id: 'headers',
      header: 'Headers',
      content: <TabHeader item={item} />,
    },
    {
      id: 'preview',
      header: 'Preview',
      content: <TabPreview item={item} />,
    },
  ];

  return <Tab tabs={tabs} />;
}

export default RightPanel;
