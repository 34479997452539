import { BrowserRouter, Routes, Route } from 'react-router-dom';

import constants from './constants';

import PageLayout from './components/Layout/PageLayout';
import AppLayout from './components/Layout/AppLayout';

import Landing from './modules/FrontLanding';
import QuickHook from './modules/QuickHook';

function routes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<Landing.Pages.LandingPage />} />
          <Route path="home" element={<Landing.Pages.LandingPage />} />
        </Route>
        <Route path="/app" element={<AppLayout />}>
          <Route
            path={constants.PATH.QUICK_HOOK}
            element={<QuickHook.Pages.QuickHookGenPage />}
          />
          <Route
            path={`${constants.PATH.QUICK_HOOK}/:hookid`}
            element={<QuickHook.Pages.QuickHookPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default routes;
