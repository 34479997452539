// Based on swagger color code https://github.com/wso2/product-apim/issues/6644
export const getColorMethod = (method) => {
  switch (method ? method.toLowerCase() : '') {
    case 'get':
      return 'sky';
    case 'post':
      return 'emerald';
    case 'put':
      return 'amber';
    case 'patch':
      return 'teal';
    case 'delete':
      return 'red';
    case 'head':
      return 'purple';
    case 'options':
      return 'cyan';
    default:
      return 'pink';
  }
};

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const isJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (err) {
    return false;
  }
};

export const isXML = (str) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(str, 'text/xml');
    if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};
