/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

function InfoSection({ name, info, gap }) {
  const className = gap ? 'py-2' : 'pb-2';
  return (
    <dl>
      <div className={className}>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{name}</h3>
      </div>
      <div className="border-t border-gray-200 bg-white ">
        <dl>
          {info.map((entry, index) => {
            const classNameC =
              info.length - 1 === index
                ? 'py-2 sm:grid sm:grid-cols-3 sm:gap-4'
                : 'py-2 sm:grid sm:grid-cols-3 sm:gap-4';
            return (
              <div className={classNameC} key={entry.key}>
                <dt className="text-sm font-medium text-gray-500">
                  {entry.key}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {entry.value}
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
    </dl>
  );
}

InfoSection.defaultProps = {
  gap: false,
};

InfoSection.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  gap: PropTypes.bool,
};

export default InfoSection;
