/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useState } from 'react';
import PropTypes from 'prop-types';

// <li className="mr-2">
{
  /* <button
  id="about-tab"
  data-tabs-target="#about"
  type="button"
  role="tab"
  aria-controls="about"
  aria-selected="true"
  className="inline-block p-4 text-base text-blue-600 rounded-tl-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-blue-500"
>
  Headers
</button>
        </li >  */
}

//   <li className="mr-2">
//   <button
//     id="services-tab"
//     data-tabs-target="#services"
//     type="button"
//     role="tab"
//     aria-controls="services"
//     aria-selected="false"
//     className="inline-block p-4 text-base  hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300"
//   >
//     Services
//   </button>
// </li>
/* eslint-disable jsx-a11y/anchor-is-valid */
function Tab({ tabs }) {
  const [active, setActive] = useState(tabs[0].id);

  function setTabActive(tabid) {
    setActive(tabid);
  }

  return (
    <div className="w-full bg-white rounded-lg border dark:bg-gray-800 dark:border-gray-700 ml-5 overflow-hidden flex flex-col">
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 bg-gray-50 rounded-t-lg border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800">
        {tabs.map((t) => {
          let className =
            'inline-block p-4 text-baserounded-tl-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-blue-500 ';
          if (t.id === active) {
            className = className.concat('text-purple-600');
          }
          return (
            <li className="mr-2" key={t.id}>
              <button
                type="button"
                role="tab"
                aria-controls={t.header}
                aria-selected="true"
                className={className}
                onClick={() => setTabActive(t.id)}
              >
                <h3 className="text-base leading-6 font-medium ">{t.header}</h3>
              </button>
            </li>
          );
        })}
      </ul>
      <div id="defaultTabContent" className="overflow-scroll overflow-scroll">
        {tabs.map((t) => {
          let className = 'p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 ';

          if (t.id !== active) {
            className = className.concat('hidden');
          }

          return (
            <div
              key={t.id}
              className={className}
              role="tabpanel"
              aria-labelledby="about-tab"
            >
              {t.content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default Tab;
