/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

import { FiXCircle } from 'react-icons/fi';

import LightBadge from '../../../../../components/Badge/LightBadge';
import IconButton from '../../../../../components/Button/IconButton';
import { getColorMethod } from '../../../../../utils';

function LeftPanelRow({ rowItem, onSelectedItem }) {
  const badgeColor = getColorMethod(rowItem.request.method);

  return (
    <li className="flex flex-row">
      <div
        className="select-none cursor-pointer flex flex-1 items-center p-3 hover:bg-gray-50 duration-200"
        onClick={() => {
          onSelectedItem(rowItem);
        }}
        onKeyDown={() => {
          onSelectedItem(rowItem);
        }}
        role="button"
        tabIndex={0}
      >
        <div className="flex-3 pl-1 w-48">
          <div className="font-medium dark:text-white text-sm text-gray-700">
            <LightBadge text={rowItem.request.method} color={badgeColor} />
            <span className="pl-1">{rowItem.id}</span>
          </div>

          <div>
            <div className="text-gray-600 dark:text-gray-200 text-sm mt-1">
              {rowItem.meta.ip}
            </div>

            <div className="text-gray-600 dark:text-gray-200 text-xs">
              {new Date(rowItem.meta.requestTimeEpoch).toLocaleString()}
            </div>
          </div>
        </div>
        <div className="flex-1 w-10 text-right flex justify-end">
          {/* <IconButton icon={<FiXCircle />} outline type="danger" /> */}
        </div>
      </div>
    </li>
  );
}

LeftPanelRow.propTypes = {
  rowItem: PropTypes.shape({
    id: PropTypes.string,
    ts: PropTypes.number,
    request: PropTypes.shape({
      body: PropTypes.object,
      method: PropTypes.string,
      headers: PropTypes.object,
    }),
    meta: PropTypes.object,
  }).isRequired,
};

export default LeftPanelRow;
