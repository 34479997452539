/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiCopy } from 'react-icons/fi';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import AlertWithButton from '../../../../components/Alert/AlertWithButton';
import IconButton from '../../../../components/Button/IconButton';
import CopyButton from '../../../../components/Button/CopyButton';
import LeftPanel from '../../components/Panel/LeftPanel/LeftPanel';
import RightPanel from '../../components/Panel/RightPanel/RightPanel';
import config from '../../../../config';
import { isEmpty } from '../../../../utils';
import { useQueryWebhookDataInterval } from './hooks';

function QuickHookPage() {
  const { hookid } = useParams();
  const { isLoading, error, data } = useQueryWebhookDataInterval(hookid);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (data && data.length > 0 && isEmpty(selectedItem)) {
      setSelectedItem(data[0]);
    }
  }, [data, isLoading]);

  function onSelectedItem(item) {
    setSelectedItem(item);
  }

  if (isLoading || data === undefined) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;

  const webhookUrl = new URL(`webhook/${hookid}`, config.apiBaseUrl).toString();

  if (isEmpty(data)) {
    return (
      <>
        <AlertWithButton
          text={`Your unique webhook link ${webhookUrl}`}
          button={
            <CopyButton
              copyText={webhookUrl}
              text="Copy"
              successText="Copied"
              icon={<FiCopy />}
            />
          }
        />

        <div className="flex flex-col  p-4 mx-auto bg-white select-none sm:h-64 rounded-2xl sm:flex-col sm:p-6 items-center">
          <div className="flex h-10 w-10 m-10">
            <span className="animate-ping inline-flex h-full w-full rounded-full bg-purple-400 opacity-100" />
          </div>
          <div className="font-medium leading-6 text-gray-600 ">
            Waiting for incoming request
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <AlertWithButton
        text={`Your unique webhook link ${webhookUrl}`}
        button={<IconButton text="Copy" icon={<FiCopy />} />}
      />

      <div className=" bg-white dark:bg-gray-800 flex flex-row mt-5 overflow-hidden">
        <LeftPanel items={data} onSelectedItem={onSelectedItem} />

        <RightPanel item={selectedItem} />
      </div>
    </>
  );
}

export default QuickHookPage;
