import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';

export default function Layout() {
  return (
    <div className="h-full overflow-y-hidden flex flex-col">
      <Header />
      <div className="w-full px-8 py-5 overflow-y-hidden flex flex-col">
        <Outlet />
      </div>
    </div>
  );
}
