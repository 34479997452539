/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import XMLViewer from 'react-xml-viewer';

import { isJSON, isXML, isEmpty } from '../../../../../../utils';

function TabPreview({ item }) {
  if (isEmpty(item)) {
    return <div />;
  }

  if (typeof item.request.body === 'object' || isJSON(item.request.body)) {
    return <ReactJson src={item.request.body} iconStyle="circle" />;
  }

  if (isXML(item.request.body)) {
    return <XMLViewer xml={item.request.body} collapsible indentSize={4} />;
  }

  return <p>{item.request.body}</p>;
}

TabPreview.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    ts: PropTypes.number,
    request: PropTypes.shape({
      body: PropTypes.object,
      method: PropTypes.string,
      headers: PropTypes.object,
    }),
    meta: PropTypes.object,
  }).isRequired,
};

export default TabPreview;
