import { Navigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import constants from '../../../../constants';

function QuickHookGenPage() {
  return (
    <Navigate to={`/app/${constants.PATH.QUICK_HOOK}/${nanoid()}`} replace />
  );
}

export default QuickHookGenPage;
