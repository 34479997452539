import { useQuery } from '@tanstack/react-query';
import config from '../../../../config';

export const useQueryWebhookDataInterval = (webhookid) => {
  let timestamp;
  const results = [];
  const { isLoading, error, data, status } = useQuery({
    queryKey: ['whData'],
    queryFn: () => {
      const url = new URL(
        timestamp
          ? `ui/webhook/${webhookid}?ts=${timestamp}`
          : `ui/webhook/${webhookid}`,
        config.apiBaseUrl
      ).toString();
      return fetch(url)
        .then((res) => {
          timestamp = Date.now();
          return res.json();
        })
        .then((resData) => {
          results.unshift(...resData.results);
          return results;
        });
    },
    refetchInterval: 1000,
    keepPreviousData: true,
  });

  return { isLoading, error, data, status };
};
