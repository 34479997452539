/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
// import PropTypes from 'prop-types'
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import humanizeString from 'humanize-string';
import { isEmpty } from '../../../../../../utils';
import InfoSection from './Components/InfoSection';

function TabHeader({ item }) {
  if (isEmpty(item)) {
    return <div />;
  }
  const metaInfo = Object.keys(item.meta).map((k) => ({
    key: humanizeString(k),
    value:
      k === 'requestTime'
        ? new Date(item.meta[k].replace(':', ' ')).toString()
        : item.meta[k],
  }));

  const headerInfo = Object.keys(item.request.headers).map((k) => ({
    key: k,
    value: item.request.headers[k],
  }));

  return (
    <>
      <InfoSection name="General" info={metaInfo} />

      <InfoSection name="Request Headers" info={headerInfo} gap />
    </>
  );
}

TabHeader.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    timestamp: PropTypes.number,
    request: PropTypes.shape({
      body: PropTypes.object,
      method: PropTypes.string,
      headers: PropTypes.object,
    }),
    meta: PropTypes.object,
  }).isRequired,
};

export default TabHeader;
