/* eslint-disable react/prop-types */
import LeftPanelList from './LeftPanelList';

function LeftPanel({ items, onSelectedItem }) {
  return (
    <div className="flex flex-col bg-white dark:bg-gray-800 rounded-lg border">
      <LeftPanelList
        items={items}
        header="Incoming Requests"
        onSelectedItem={onSelectedItem}
      />
    </div>
  );
}

export default LeftPanel;
