/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

function LightBadge({ color, text }) {
  let className =
    'px-2 py-1 text-xs rounded text-purple-500 border border-purple-500 font-medium';

  switch (color) {
    case 'sky':
      className =
        'px-2 py-1 text-xs rounded text-sky-500 border border-sky-500 font-medium';
      break;
    case 'emerald':
      className =
        'px-2 py-1 text-xs rounded text-emerald-500 border border-emerald-500 font-medium';
      break;
    case 'amber':
      className =
        'px-2 py-1 text-xs rounded text-amber-500 border border-amber-500 font-medium';
      break;
    case 'teal':
      className =
        'px-2 py-1 text-xs rounded text-teal-500 border border-teal-500 font-medium';
      break;
    case 'red':
      className =
        'px-2 py-1 text-xs rounded text-red-500 border border-red-500 font-medium';
      break;
    case 'purple':
      className =
        'px-2 py-1 text-xs rounded text-purple-500 border border-purple-500 font-medium';
      break;
    case 'cyan':
      className =
        'px-2 py-1 text-xs rounded text-cyan-500 border border-cyan-500 font-medium';
      break;
    default:
      className =
        'px-2 py-1 text-xs rounded text-sky-500 border border-sky-500 font-medium';
  }

  return <span className={className}>{text}</span>;
}

LightBadge.defaultProps = {
  color: 'purple',
};

LightBadge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default LightBadge;
