/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useSmoothScroll from 'react-smooth-scroll-hook';

function Header() {
  const ref = useRef(document.getElementById('root'));

  const location = useLocation();
  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 100,
    direction: 'y',
  });

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => scrollTo(location.hash), 200);
    }
  }, [location]);

  return (
    <section className="w-full px-8 text-gray-700 bg-white">
      <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
        <div className="relative flex flex-col md:flex-row">
          <Link
            to="/"
            className="flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0"
          >
            <span className="mx-auto text-xl font-black leading-none text-gray-900 select-none">
              instahook
              <span className="text-purple-600" data-primary="indigo-600">
                .co
              </span>
            </span>
          </Link>
          <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-gray-200">
            <Link
              to={{
                pathname: '/',
              }}
              className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900"
            >
              Home
            </Link>
            {/* <Link
              to={{
                pathname: '/',
                hash: '#features',
              }}
              className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900"
            >
              Features
            </Link> */}
            {/* <a
              href="#_"
              className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900"
            >
              Pricing
            </a> */}
            {/* <a
              href="#_"
              className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900"
            >
              Blog
            </a> */}
          </nav>
        </div>
        {/* <div className="inline-flex items-center ml-5 space-x-6 lg:justify-end">
          <a
            href="#"
            className="text-base font-medium leading-6 text-gray-600 whitespace-no-wrap transition duration-150 ease-in-out hover:text-gray-900"
          >
            Sign in
          </a>
          <a
            href="#"
            className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-purple-600 border border-transparent rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            data-rounded="rounded-md"
            data-primary="indigo-600"
          >
            Sign up
          </a>
        </div> */}
      </div>
    </section>
  );
}

export default Header;
