/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import { FiInfo } from 'react-icons/fi';

function ClosableAlert({ text, button }) {
  return (
    <div className="text-lg text-purple-700 bg-purple-200 dark:bg-gray-800 rounded-md">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex">
              <FiInfo />
            </span>
            <p className="ml-2 text-purple-700 truncate">
              <span>{text}</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            {button}
          </div>
        </div>
      </div>
    </div>
  );
}

ClosableAlert.propTypes = {
  text: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired,
};

export default ClosableAlert;
